import React
// {lazy}
 from "react";
import { Switch,BrowserRouter, Route ,Redirect } from "react-router-dom";
// import { useSelector } from "react-redux";
import PrivateRoute from "./Protected";
import IndexRoutes from "./index";
import FullLayout from "../layouts/FullLayout";
import NotFound from '../layouts/layout-components/NotFound';
// const TableIndex = lazy(() => import("../views/table/TableIndex"));
// const HumanInterface = lazy(() => import("../views/pdf/HumanInterface"));
// const HumanInterfaceEdit = lazy(() => import("../views/pdf/HumanInterfaceEdit"));
// const Header = lazy(() => import("../layouts/layout-components/header/Header"));

const Routes = () => {
  // const settings = useSelector((state) => state.settings);
  return (
    <BrowserRouter>
     <Switch>
     <Route exact path="/">
          <Redirect to="/client/TableIndex" />
        </Route>
        {/* <Alert /> */}
        {/* <Route exact path="/Login" component={BlankLayout} />; */}
        {IndexRoutes.map((prop, key) => {
          return (
            <PrivateRoute
              path={prop.path}
              key={key}
              component={prop.component}
            />
            // <PrivateRoute path="/pdf/:file" key="/pdf/256" component={Pdf} />
        
          );
        })}
        {/* <Route exact path="/pdf/:file" component={Pdf}/> */}
        <Route path="/client/TableIndex" component={FullLayout} />
        <Route component={NotFound} />
         

      </Switch>
 
    </BrowserRouter>
  );
};

export default Routes;





