import {
  GET_PDF,
  GET_JSON,
  GET_PENDING_DATA,
  GET_QA_DATA,
  GET_APPROVED_DATA,
  GET_AUTO_DATA,
  UPDATE_JSON,
  GET_QUESTION_ANSWER,
  SEND_TO_QA,
  CLEAR_TABLE,
  SEND_TO_BAD,
  DELETE_TEMPLATE,
  GET_HI_QUESTION_DATA,
  REMOVE_HI_QUESTION_DATA,
  GET_TRANSLATEPDF_DATA,
  GET_QUESTIONMODAL_ANSWER,
  REMOVE_QUESTIONMODAL_ANSWER,
  GET_BOUNDINGBOX_DATA,
  GET_BOUNDINGBOX_DATA_ANCHORTEXT,
  GET_SESSION_REPORT,
  GET_PENDIND_LINK_DATA,
  GET_COUNTRY_DATA,
  UPDATE_LINK,
  RESET_UPDATELINKLOADINGSTATUS,
  GET_CHATGPT_QUESTION_ANSWER,
  SET_SUBHEADING_HEADER,
  SET_SEARCH_DATA,
  SET_AUTOTAB_PAGINATION,
  SET_ALERT_TAB_PAGINATION,
  DOWNLOAD_PRIVACY,
  GET_PROCESSED_DATA_FROM_CHATGPT,
  GET_HI_QUESTION_CHATGPT_DATA,
  REMOVE_HI_QUESTION_CHATGPT_DATA,
  GET_QUESTIONMODAL_ANSWER_FROM_CHATGPT,
  REMOVE_QUESTIONMODAL_CHATGPT_ANSWER,
  LOADING_CHATGPT_QUESTION_ANSWER,
  LOADING_QUESTION_ANSWER,
  LOADING_ANSWER_ERROR,
  CHATGPT_RESPONSE_ERROR,
  API_EXECUTION_COMPLETED,
  LOADING_TRANSLATE_PDF_ERROR,
  SET_FORM_DATA,
  GET_ALERT,
  CLEAR_PENDING_TABLE,
  CLEAR_ALERT_TABLE,
  IS_REMOVING_REDUNTANT_TEMPLATES
} from '../actions/types';
import { env as environment } from "../actions/environment";
const initialState = {
  pdf: [],
  alertData:[],
  pendingData: [],
  qaData: [],
  approveData: [],
  pendingLinkData: [],
  autoData: [],
  json: [],
  loading: true,
  docid: null,
  templateId: null,
  url: null,
  updatemsg: null,
  loadingauto: true,
  loadingPending:true,
  loadingAlert:true,
  questionanswer: {},
  loadinganswer: false,
  sendtoqa: {},
  limit: 0,
  badData: [],
  loadingbad: true,
  loadingdelete: true,
  questiondata: {},
  loadingquestion: true,
  translatepdfdata: null,
  loadingtranslatepdf: true,
  questionmodalanswer: {},
  loadingquestionmodalanswer: true,
  questionmodalanswerfromchatgpt: {},
  loadingquestionmodalanswerchatgpt: true,
  boundingboxdata: {},
  boundingboxdataanchortext: {},
  sessiondata: {},
  loadingsessiondata: true,
  countrydata: [],
  updatelinkloadingstatus: true,
  updatelinkresponse: null,
  chatgptresponse: {},
  loadingchatgptresponse: false,
  subheading: "pending",
  searchdata: "",
  autotabpageno: 0,
  alerttabpageno:0,
  downloadpdf:localStorage.getItem("email")?environment.AuthenticationForDownloadOfPdf.includes(localStorage.getItem("email")):false,
 chatGptDataFromPdf:null,
 loadingquestionchatgpt:true, 
 apiIsLoading:false,
 isRemovingRedundantTemplates:true,
 formData: {
  enteredString: '',
  selectedOption: '',
}
}

export default function (state = initialState, action) {
  const { type, payload, limitforautotab } = action;

  switch (type) {
    case GET_PDF:
      return {
        ...state,
        pdf: payload,
        loading: false
      }

      case GET_ALERT:
        return {
          ...state,
          alertData: payload,
          loading: false,
          loadingAlert:false,
        }

    case GET_PENDING_DATA:
      return {
        ...state,
        pendingData: payload,
        loading: false,
        loadingpending: false,
      }

    case GET_QA_DATA:
      return {
        ...state,
        qaData: payload,
        loading: false
      }

    case GET_APPROVED_DATA:
      return {
        ...state,
        approveData: payload,
        loading: false,
      }
    case GET_PENDIND_LINK_DATA:
      return {
        ...state,
        pendingLinkData: payload,
        loading: false
      }
    case GET_AUTO_DATA:
      return {
        ...state,
        autoData: payload,
        loadingauto: false,
        limit: limitforautotab
      }

    case GET_JSON:
      return {
        ...state,
        json: payload.body.objects,
        loading: false
      }

    case UPDATE_JSON:
      return {
        ...state,
        updatemsg: payload
      }
    case GET_QUESTION_ANSWER:
      return {
        ...state,
        questionanswer: payload,
        loadinganswer: false
      }
    case SEND_TO_QA:
      return {
        ...state,
        sendtoqa: payload,
      }
    case CLEAR_TABLE:
      return {
        ...state,
        autoData: [],
        loadingauto: true,
      }
      case CLEAR_PENDING_TABLE:
      return {
        ...state,
        pendingData: [],
        loadingpending: true,
      }
      case CLEAR_ALERT_TABLE:
        return {
          ...state,
          alertData: [],
          loadingAlert: true,
        }
      case IS_REMOVING_REDUNTANT_TEMPLATES:
        return {
          ...state,
          isRemovingRedundantTemplates:payload
        }
    case SEND_TO_BAD:
      return {
        ...state,
        badData: [],
        loadingbad: false,
      };
    case DELETE_TEMPLATE:
      return {
        ...state,
        loadingdelete: false,
      };
    case GET_HI_QUESTION_DATA:
      return {
        ...state,
        questiondata: payload,
        loadingquestion: false,
      };
    case GET_TRANSLATEPDF_DATA:
      return {
        ...state,
        translatepdfdata: payload,
        loadingtranslatepdf: false,
      };
    case GET_QUESTIONMODAL_ANSWER:
      return {
        ...state,
        questionmodalanswer: payload,
        loadingquestionmodalanswer: false
      }

    case REMOVE_QUESTIONMODAL_ANSWER:
      return {
        ...state,
        questionmodalanswer: {}
      }
    case REMOVE_HI_QUESTION_DATA:
      return {
        ...state,
        questiondata: {},
        loadingquestion: true,
      }
      case REMOVE_HI_QUESTION_CHATGPT_DATA:
      return {
        ...state,
        questiondatachatgpt: {},
        loadingquestionchatgpt: true,
      }
    case GET_BOUNDINGBOX_DATA:
      return {
        ...state,
        boundingboxdata: payload
      }
    case GET_BOUNDINGBOX_DATA_ANCHORTEXT:
      return {
        ...state,
        boundingboxdataanchortext: payload
      }
    case GET_SESSION_REPORT:
      return {
        ...state,
        sessiondata: payload,
        loadingsessiondata: false
      }
    case GET_COUNTRY_DATA:
      return {
        ...state,
        countrydata: payload,
      }
    case UPDATE_LINK:
      return {
        ...state,
        updatelinkresponse: payload,
        updatelinkloadingstatus: false
      }
    case RESET_UPDATELINKLOADINGSTATUS:
      return {
        ...state,
        updatelinkloadingstatus: true
      }
    case GET_CHATGPT_QUESTION_ANSWER:
      return {
        ...state,
        chatgptresponse: payload,
        loadingchatgptresponse: false
      }
    case SET_SUBHEADING_HEADER:
      return {
        ...state,
        subheading: payload
      }
    case SET_SEARCH_DATA:
      return {
        ...state,
        searchdata: payload
      }
    case SET_AUTOTAB_PAGINATION:
      return {
        ...state,
        autotabpageno: payload
      }
      case SET_ALERT_TAB_PAGINATION:
        return {
          ...state,
          alerttabpageno: payload
        }
    case DOWNLOAD_PRIVACY:
      return {
        ...state,
        downloadpdf: payload
      }
    case GET_PROCESSED_DATA_FROM_CHATGPT:
      return{
        ...state,
        chatGptDataFromPdf:payload
      }
      case GET_HI_QUESTION_CHATGPT_DATA:
      return{
        ...state,
        questiondatachatgpt: payload,
        loadingquestionchatgpt: false,
      }
      case GET_QUESTIONMODAL_ANSWER_FROM_CHATGPT:
      return{
        ...state,
        questionmodalanswerfromchatgpt: payload,
        loadingquestionmodalanswerchatgpt: false
      }
      case REMOVE_QUESTIONMODAL_CHATGPT_ANSWER:
      return{
        ...state,
        questionmodalanswerfromchatgpt: {},
        loadingquestionmodalanswerchatgpt: false
      }
      case LOADING_CHATGPT_QUESTION_ANSWER:
      return{
        ...state,
        loadingchatgptresponse: true
      }
      case LOADING_QUESTION_ANSWER:
      return{
        ...state,
        loadinganswer: true
      }

      case CHATGPT_RESPONSE_ERROR:
        return{
          ...state,
          loadingchatgptresponse: false,
        }
      case LOADING_ANSWER_ERROR:
        return{
          ...state,
          loadinganswer: false,
        }
      case API_EXECUTION_COMPLETED:
        return{
          ...state,
          apiIsLoading:true,
        }
      case LOADING_TRANSLATE_PDF_ERROR:
        return{
          ...state,
          loadingtranslatepdf: false,
        }

        case SET_FORM_DATA:
         console.log("inreduc",action.payload);
          return {
            ...state,
            formData: action.payload,
          };

          case "CLEAR_STORE":
            return {...initialState};

    default:
      return state;
  }

}
