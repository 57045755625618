import { lazy } from "react";
//tableindex
const TableIndex=lazy(() => import("../views/table/TableIndex"));
const ApproveTable=lazy(() => import("../views/table/ApproveTable"));
const AutoTable=lazy(() => import("../views/table/AutoTable"));
const PendingTable=lazy(() => import("../views/table/PendingTable"));
const QATable=lazy(() => import("../views/table/QATable"));
//ValidationButtons
// const ValidationButtons = lazy(() => import("../views/validation-buttons/ValidationButtons"));
// const Button = lazy(() => import("../views/validation-buttons/Button"));
// const ButtonSetting = lazy(() => import("../views/validation-buttons/ButtonSetting"));
// const ReqDoc = lazy(() => import("../views/validation-buttons/ReqDoc"));
//Requests Sent
// const NewRequest = lazy(() => import("../views/Requests-sent/NewRequest"));
// const Pending = lazy(() => import("../views/Requests-sent/Pending"));
//const Completed = lazy(() => import("../views/Requests-sent/Completed"));

//Documents Recieved
// const ReviewPending = lazy(() => import("../views/documents-recieved/ReviewPending"));
// const Approved = lazy(() => import("../views/documents-recieved/Approved"));
// const Rejected = lazy(() => import("../views/documents-recieved/Rejected"));
// const Pdf = lazy(() => import("../views/documents-recieved/Pdf"));

//See coverage
//Developers
// const ApiReference = lazy(() => import("../views/developers/ApiReference"));
// const ApiIntegration = lazy(() => import("../views/developers/ApiIntegration"));

// const Settings = lazy(() => import("../views/developers/Settings"));
// const Form = lazy(() => import("../views/developers/Form"));
//Manage Account
// const Users = lazy(() => import("../views/manage-account/Users"));
// const Billing = lazy(() => import("../views/manage-account/Billing"));
// const OrganizationsDetail = lazy(() => import("../views/manage-account/OrganizationalDetail"));
//const userRoles = localStorage.getItem('roles');

//const TestImage = lazy(() => import("../views/test/TestImage"));

var ThemeRoutes = [
    {
		path: "/client/TableIndex",
		name: "Table inex",
		icon: "check-square",
		component: TableIndex,
	},
	{
		path: "/client/ApproveTable",
		name: "Table inex",
		icon: "check-square",
		component: ApproveTable,
	},
	{
		path: "/client/AutoTable",
		name: "Table inex",
		icon: "check-square",
		component: AutoTable,
	},
	{
		path: "/client/PendingTable",
		name: "Table inex",
		icon: "check-square",
		component: PendingTable,
	},
	{
		path: "/client/QATable",
		name: "Table inex",
		icon: "check-square",
		component: QATable,
	}
	// {
	// 	path: "/client/admin-dash/button-setting/:id",
	// 	name: "New Validation buttons",
	// 	icon: "check-square",
	// 	component: ButtonSetting,
	// },

	// {
	// 	path: "/client/admin-dash/req-document/:id",
	// 	name: "New Validation buttons",
	// 	icon: "check-square",
	// 	component: ReqDoc,
	// },

	// {
	// 	path: "/client/validation-buttons/button-setting/:id",
	// 	name: "New Validation buttons",
	// 	icon: "check-square",
	// 	component: ButtonSetting,
	// },

	// {
	// 	path: "/client/validation-buttons/req-document/:id",
	// 	name: "New Validation buttons",
	// 	icon: "check-square",
	// 	component: ReqDoc,
	// },
	// {
	// 	path: "/client/admin-dash",
	// 	name: "Validation buttons",
	// 	icon: "check-square",
	// 	component: Button,
	// },
	// {
	// 	path: "/client/validation-buttons",
	// 	name: "Validation buttons",
	// 	icon: "check-square",
	// 	component: Button,
	// },

	// {
	// 	path: "/client/dashboard",
	// 	name: "New Validation buttons",
	// 	icon: "check-square",
	// 	component: ValidationButtons,
	// },
	// {
	// 	path: "/client/button-setting/:id",
	// 	name: "New Validation buttons",
	// 	icon: "check-square",
	// 	component: ButtonSetting,
	// },

	// {
	// 	path: "/client/req-document/:id",
	// 	name: "New Validation buttons",
	// 	icon: "check-square",
	// 	component: ReqDoc,
	// },

	// {
	// 	collapse: true,
	// 	path: "/client/request-sent",
	// 	name: "Requests sent",
	// 	state: "dashboardpages",
	// 	icon: "home",
	// 	child: [
	// 		{
	// 			path: "/client/request-sent/new-request",
	// 			name: "New request",
	// 			mini: "B",
	// 			icon: "mdi mdi-adjust",
	// 			component: NewRequest,
	// 		},
	// 		// {
	// 		//   path: "/client/request-sent/pending",
	// 		//   name: "Pending",
	// 		//   mini: "B",
	// 		//   icon: "mdi mdi-adjust",
	// 		//   component: Pending,
	// 		// },
	// 		// {
	// 		//   path: "/client/request-sent/completed",
	// 		//   name: "Completed",
	// 		//   mini: "B",
	// 		//   icon: "mdi mdi-adjust",
	// 		//   component: Completed,
	// 		// },
	// 	],
	// },
	// {
	// 	collapse: true,
	// 	path: "/client/documents-received",
	// 	name: "Documents received",
	// 	state: "uicomponents",
	// 	icon: "cpu",
	// 	child: [
	// 		{
	// 			path: "/client/documents-received/review-pending",
	// 			name: "Review pending",
	// 			icon: "mdi mdi-comment-processing-outline",
	// 			component: ReviewPending,
	// 		},
	// 		{
	// 			path: "/client/documents-received/approved",
	// 			name: "Approved",
	// 			icon: "mdi mdi-arrange-send-backward",
	// 			component: Approved,
	// 		},
	// 		{
	// 			path: "/client/documents-received/rejected",
	// 			name: "Rejected",
	// 			icon: "mdi mdi-equal",
	// 			component: Rejected,
	// 		},
	// 	],
	// },
	// {
	// 	//path: "/client/see-coverage",
	// 	path: "/client/see-coverage",
	// 	name: "See coverage",
	// 	icon: "check-square",
	// 	component: SeeCoverage,
	// 	target: "_blank",
	// },

	// {
	// 	collapse: true,
	// 	path: "/client/developers",
	// 	name: "Developers",
	// 	state: "formlayoutPages",
	// 	icon: "file-text",
	// 	child: [
	// 		{
	// 			path: "/client/developers/api-refrence",
	// 			name: "API reference",
	// 			icon: "mdi mdi-priority-low",
	// 			component: ApiReference,
	// 		},
	// 		{
	// 			path: "/client/developers/api-reference",
	// 			name: "API reference",
	// 			icon: "mdi mdi-priority-low",
	// 			component: ApiReference,
	// 		},
	// 		{
	// 			path: "/client/developers/api-refrence/:id",
	// 			name: "API reference",
	// 			icon: "mdi mdi-priority-low",
	// 			component: ApiReference,
	// 		},
	// 		{
	// 			path: "/client/developers/api-reference/:id",
	// 			name: "API reference",
	// 			icon: "mdi mdi-priority-low",
	// 			component: ApiReference,
	// 		},

	// 		{
	// 			path: "/client/developers/api-integration",
	// 			name: "API & Integration",
	// 			icon: "mdi mdi-priority-low",
	// 			component: ApiIntegration,
	// 		},
	// 		{
	// 			path: "/client/developers/logs",
	// 			name: "Logs",
	// 			icon: "mdi mdi-select-all",
	// 			component: Logs,
	// 		},
	// 	],
	// },

	// {
	//   path: "/client/test-image",
	//   name: "New Validation buttons",
	//   icon: "check-square",
	//   component: TestImage,
	// },

	// {
	// 	collapse: true,
	// 	path: "/client/manage-account",
	// 	name: "Manage account",
	// 	state: "formpickerPages",
	// 	icon: "droplet",
	// 	child: [
	// 		{
	// 			path: "/client/manage-account/users",
	// 			name: "Users",
	// 			icon: "mdi mdi-calendar-clock",
	// 			component: Users,
	// 		},
	// 		{
	// 			path: "/client/manage-account/billing",
	// 			name: "Billing",
	// 			icon: "mdi mdi-tag-multiple",
	// 			component: Billing,
	// 		},
	// 		{
	// 			path: "/client/manage-account/organizations-details",
	// 			name: "Organizations details",
	// 			icon: "mdi mdi-tag-multiple",
	// 			component: OrganizationsDetail,
	// 		},
	// 	],
	// },
];
export default ThemeRoutes;
