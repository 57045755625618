import { lazy } from "react";
const FullLayout = lazy(() => import("../layouts/FullLayout"));
const BlankLayout = lazy(() => import("../layouts/BlankLayout"));
// const FullAuthLayout = lazy(() => import("../layouts/FullAuthLayout"));
//const Pdf = lazy(() => import("../views/documents-recieved/Pdf.js"));
const TableIndex = lazy(() => import("../views/table/TableIndex"));
const HumanInterface = lazy(() => import("../views/pdf/HumanInterface"));
const HumanInterfaceEdit = lazy(() => import("../views/pdf/HumanInterfaceEdit"));
// const Header = lazy(() => import("../layouts/layout-components/header/Header"));

var indexRoutes = [
	{ path: "/pdf-view", name: "pdf", component: HumanInterface },
	{ path: "/pdf-edit", name: "pdf", component: HumanInterfaceEdit },
    { path: "/tableindex", name: "pdf", component: TableIndex },
	{ path: "/client", name: "Dashboard", component: FullLayout },
	{ path: "/", name: "Athentication", component: BlankLayout },
];

export default indexRoutes;
